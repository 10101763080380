import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

import styles from '../styles/page-header.module.scss';

const TitleHeader = (props) => (
	<h1>{props.children}</h1>
);

const PageHeader = ({ title, to, titleComponent }) => {
	const data = useStaticQuery(
		graphql`
          query {
            pattern: file(relativePath: { eq: "rose-gold-header.jpg" }) {
                childImageSharp {
                  fluid(quality: 70, maxWidth: 4096) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
          }
        `
	);

	const Title = () => {
		const TitleComponent = titleComponent || TitleHeader;

		return (
			<TitleComponent>
				<Link to={to}>
					{title}
				</Link>
			</TitleComponent>
		);
	};

	return (
		<BackgroundImage
			Tag='section'
			className={styles.header}
			fluid={data.pattern.childImageSharp.fluid}
		>
			<div className={styles.content}>
				{Title()}
			</div>
		</BackgroundImage>
	);
};

PageHeader.propTypes = {
	title: PropTypes.string.isRequired
};

export default PageHeader;