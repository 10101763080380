import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';

import SEO from '../components/seo';
import PageHeader from '../components/page-header';

import styles from '../styles/our-partners.module.scss';

const OurPartnersPage = ({ data }) => (
    <>
        <SEO
            title='Our Partners'
            path='/our-partners/'
            description='Every month we find a new store to feature, take a look at our partners.'
        />
        <PageHeader
			title='Our Partners'
			to='/our-partners/'
		/>
        <section className={styles.main}>
            <ul className={clsx(styles.partners, styles.content)}>
                {data.vendors.edges.map((x, i) => (
                    <li key={i}>
                        <Link
                            to={`/our-partners/${x.node.handle}/`}
                            className={styles.card}
                        >
                            <Img
                                fluid={x.node.logo.childImageSharp.fluid}
                                alt={x.node.name}
                                className={styles.logo}
                                objectFit='contain'
                                imgStyle={{
                                    objectFit: 'contain'
                                }}
                            />
                            <div className={styles.name}>{x.node.name}</div>
                        </Link>
                    </li>
                ))}
                <li>
                    <div className={clsx(styles.card, styles.more)}>
                        <div className={styles.logo}></div>
                        <div className={styles.name}>New brand added every month!</div>
                    </div>
                </li>
            </ul>
        </section>
    </>
);

export const query = graphql`
    query {
        vendors: allVendorsJson {
            edges {
                node {
                    name
                    handle
                    logo {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 480) {
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        }                
    }
`;

export default OurPartnersPage;